<template>
	<div class="studioadd">
	
	<div class="maincontent">
		<div class="selectgroup">
			
			<div class="mc_div">
				<div class="mcd_title">
					<span style="color: #FD3D1E">*</span>
					<span>用户名</span>
				</div>
				<div class="mcd_content">
					<input placeholder="请输入" v-model='form.name' />
				</div>
			</div>
			<div class="mc_div">
				<div class="mcd_title">
					<span style="color: #FD3D1E">*</span>
					<span>手机号码</span>
				</div>
				<div class="mcd_content">
					<input type="tel" placeholder="请输入" v-model='form.telphone' />
				</div>
			</div>
			<div class="sure_add" v-if="form.telphone=='' || form.name==''">
				<span>新增人员</span>
			</div>
			<div class="sure_add cansure" @click="sureCreate" v-else>
				<span>新增人员</span>
			</div>
		</div>
		</div>
	</div>
</template>

<script>

import until from '@/api/until.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import tool_api from '@/api/teaching_studio_tool.js'
export default {
	name: 'studioadd',
	data() {
		return {
			form:{
					app_id: '',
					computer_code: '',
					salt: '',
					name: '',
					telphone: '',
				}
		}
	},
	components: {

	},
	mounted() {
		document.title="新增人员"
		this.getSalt()
	},
	methods: {
		updateData() {

		},
		//获取表单验证码
		async getSalt() {
			let computer_code = until.getCookie("guid").substring(0, 6)
			let data = await tool_api.ToolForm_saltGet({ computer_code });
			if (data.errcode == 0) {

				this.form.salt = data.other;
			}
		},
		//新建小组
		async sureCreate() {
			let form = this.form;
			this.$toast.loading({
				message: "加载中...",
				forbidClick: true,
				duration: 0,
			});
			form.app_id = this.$root.app_id;
			form.computer_code = until.getCookie("guid").substring(0, 6)
			let data = await studio_api.Teaching_studio_apiMemberUserAddPost(form)
			if (data.errcode == 0) {
				this.$toast.clear();
				this.$toast("添加成功")
				
				this.$root.isLoading = true
				window.history.go(-1);
			}
		},
	}
}
</script>
<style scoped>
.studioadd {
	width: 100%;
	height: 100vh;
	display: inline-block;
}

.selectgroup {
	margin-top: 60px;
}

.mc_div {
	width: 100%;
	display: inline-block;
	margin-top: 40px;
}

:first-child.mc_div {
	margin-top: 0;
}

.mcd_title {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 26px;
	color: #666666;
}

.mcd_title span:first-child {

	margin-right: 8px;
}

.mcd_content {
	width: 100%;
	margin-top: 16px;
	display: flex;
	align-items: center;
	justify-content: flex-start;

}

.mcd_content input {
	width: 100%;
	height: 88px;
	border: 1px solid #E6E6E6;
	background: #ffffff;
	border-radius: 8px;
	padding-left: 30px;
	box-sizing: border-box;
	font-size: 26px;
}

.mcdc_addimg {
	width: 145px;
	height: 145px;
	background: #FFFFFF;
	border-radius: 8px;
	border: 1px solid #E6E6E6;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mcdc_addimg .iconfont {
	font-size: 48px;
	color: #999999;
}

.cansure {
	background: #79CA6A;
}
</style>
